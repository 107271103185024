<template>
  <div id="app">
    <app-hd v-if="header_show" :disable="meatDis"></app-hd>
    <keep-alive>
      <router-view v-on:showheader="showheader" v-on:showfooter="showfooter" v-on:changrMeta="changrMeta" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-on:showheader="showheader" v-on:showfooter="showfooter" v-on:changrMeta="changrMeta" v-if="!$route.meta.keepAlive"></router-view>
    <app-ft v-if="footer_show" :disable="meatDis"></app-ft>
  </div>
</template>

<script>
  import Head from './components/public/head';
  import Foot from './components/public/foot';
  export default {
    name: 'app',
    data() {
      return {
        header_show: true,
        footer_show: true,
        meatDis: false
      }
    },
    components: {
      'app-hd':Head,
      'app-ft':Foot,
    },
    mounted () {
      this.getInit()
    },
    methods: {
      showheader(bool) {
        this.header_show = bool
      },
      showfooter(bool) {
        this.footer_show = bool
      },
      changrMeta(val) {
        console.log(val)
        console.log(123)
        this.meatDis = true
      },
      getInit() {
        this.$open()
      }
    }
  }
</script>


<style lang="scss">
  #app {
    /*font-family: "Microsoft Yahei";*/
    font-family: PingFangSC-Regular, PingFang SC;
    /*font-family: SimSun;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    min-width: 1200px;
  }

  body{
    margin: 0;
    padding: 0;
  }

  div,input{
    box-sizing: border-box;
  }

  .btn{
    cursor: pointer;
    user-select: none;
  }

  input{
    outline: none;
  }

  .main{
    width: 1200px;
    margin: 0 auto;
  }

  /*修改浏览器滚动条*/
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #E5E5E5;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #B2B2B2;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(162, 162, 163);
  }
</style>
