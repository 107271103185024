import Vue from 'vue'
import axios from 'axios'
import store from '../store/index.js'

// axios.defaults.baseURL = 'https://pz.pdddd.com'
axios.defaults.baseURL = 'http://101.132.122.204:8064'

// 请求拦截器
axios.interceptors.request.use(function(config) {

    // console.log(config)
    // 获取url，放入请求头，识别商户
    let storeUrl = window.location.protocol + '//' + window.location.host
        // config.headers['Domain'] = "http://18272767983.palmes.shop"
    config.headers['Domain'] = storeUrl

    if (store.state.SequenceNo != '' || window.localStorage.getItem('SequenceNo')) {
        config.headers['SequenceNo'] = store.state.SequenceNo || window.localStorage.getItem('SequenceNo')
    }

    if (config.url == '/api/Shop/init') {
        config.headers['SequenceNo'] = ''
    }

    return config
})

// 响应拦截器
axios.interceptors.response.use(function(response) {
    // console.log('res',response)
    // 添加seq
    let seq = response.data.sequenceNo
    store.commit('setSeq', seq)
    window.localStorage.setItem('SequenceNo', seq)
        // console.log('state', store.state.SequenceNo)
    getrule(response)
    if (response.status === 200) {
        return Promise.resolve(response.data)
    } else {
        return Promise.reject(response.data)
    }
}, function(err) {
    // console.log(err)
    let seq = (new Date).getTime() + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)
    store.commit('setSeq', seq)
    window.localStorage.setItem('SequenceNo', seq)
    return Promise.reject(err.response)
})

//返回一个Promise(发送post请求)
export function POST(url, param) {
    return new Promise((resolve, reject) => {
        axios.post(url, param)
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

// 返回一个Promise(发送get请求)
export function GET(url, param) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

function getrule(res) {
    // console.log('rule',res)
    // 店铺到期 maturity
    if (res.status == 401) {
        this.$router.push({
            path: '/maturity'
        })
    }
    // 款式未上架 nothing
    if (res.status == 405) {
        this.$router.push({
            path: '/nothing'
        })
    }
    // 钻石被卖掉了 regret
    if (res.status == 302) {
        this.$router.push({
            path: '/regret'
        })
    }
    // 404 error
    if (res.status == 404) {
        this.$router.push({
            path: '/error'
        })
    }
}

export default {
    POST,
    GET
}