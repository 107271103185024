import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 请求头
    SequenceNo:'',
    // 店铺信息
    shop: ''
  },
  mutations: {
    // 设置每次发送的请求头
    setSeq(state, string){
      state.SequenceNo = string
    },
    // 设置店铺信息
    setShop(state, obj) {
      state.shop = obj
    }
  },
  actions: {
  },
  modules: {
  }
})
