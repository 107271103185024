import {POST, GET} from './index'

// api/Shop/init 店铺初始化
function init (params) {
  return GET('/api/Shop/init',params)
}

// api/Shop/getshop 获取店铺列表
function getshop (params) {
  return GET('/api/Shop/getshop',params)
}

export default {
  init,
  getshop
}
